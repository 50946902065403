import React from "react";

function Widget() {

    return (
      <div className="absolute top-3 right-12">

        <div className="absolute bottom-1 right-2">
        <div class="elfsight-app-d50afa42-6013-498a-8f64-a4d40bbba74e" data-elfsight-app-lazy></div>
        </div>

      </div>
    );

}

export default Widget;
